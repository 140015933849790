import React from 'react';
import './Hero.css';  // 确保这行存在

const Hero = () => {
  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="hero" className="hero">
      <video autoPlay loop muted playsInline className="hero-video">
        <source src="/hero-video.mp4" type="video/mp4" />
        // Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <h1>这是一个完全由AI生成的网站</h1>
        <h2>AI 2.0，释放每个人潜能</h2>
        <div className="cta-buttons">
          <button className="cta-button primary">立即体验</button>
          <button className="cta-button secondary" onClick={scrollToAbout}>了解更多</button>
        </div>
        <div className="scroll-down-arrow">
          <img src="/images/down-arrow.png" alt="Scroll down" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
