import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import './ChatBot.css';
import config from '../config/chatConfig';

const ChatBot = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [position, setPosition] = useState({ right: 20, bottom: 20 });
  const [currentAssistant, setCurrentAssistant] = useState('AI客服小容');
  const chatbotRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startPosRef = useRef({ x: 0, y: 0 });
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  useImperativeHandle(ref, () => ({
    initializeChat: (assistant) => {
      setCurrentAssistant(assistant);
      setIsOpen(true);
      setMessages([{ text: config.initialPrompts[assistant], user: false }]);
    }
  }));

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDraggingRef.current && chatbotRef.current) {
        const dx = startPosRef.current.x - e.clientX;
        const dy = startPosRef.current.y - e.clientY;
        setPosition(prev => ({
          right: prev.right + dx,
          bottom: prev.bottom + dy
        }));
        startPosRef.current = { x: e.clientX, y: e.clientY };
      }
    };

    const handleMouseUp = () => {
      isDraggingRef.current = false;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    startPosRef.current = { x: e.clientX, y: e.clientY };
  };

  const toggleChat = () => {
    if (!isOpen) {
      // 当打开聊天窗口时，初始化为 AI 客服小容
      setCurrentAssistant('AI客服小容');
      setMessages([{ text: config.initialPrompts['AI客服小容'], user: false }]);
    }
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '' || isTyping) return;

    setMessages(prev => [...prev, { text: input, user: true }]);
    setInput('');
    setIsTyping(true);

    const requestBody = {
      model: 'gpt-3.5-turbo-16k',
      messages: [
        {
          role: 'system',
          content: config.initialPrompts[currentAssistant]
        },
        {
          role: 'user',
          content: input
        }
      ],
      stream: false
    };

    try {
      const response = await fetch(config.apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${config.apiKey}`
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const botResponse = data.choices[0].message.content;

      setMessages(prev => [...prev, { text: botResponse, user: false }]);
    } catch (error) {
      setMessages(prev => [...prev, { text: `抱歉，发生了错误：${error.message}`, user: false }]);
    } finally {
      setIsTyping(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div 
      className="chatbot-container" 
      style={{ bottom: `${position.bottom}px`, right: `${position.right}px` }}
      ref={chatbotRef}
    >
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h3>AI 助手 - {currentAssistant}</h3>
            <button onClick={toggleChat}>关闭</button>
          </div>
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.user ? 'user' : 'bot'}`}>
                {message.text}
              </div>
            ))}
            {isTyping && <div className="message bot">正在输入...</div>}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleSubmit} className="chat-input">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="输入您的问题..."
              disabled={isTyping}
            />
            <button type="submit" disabled={isTyping}>
              {isTyping ? '等待中...' : '发送'}
            </button>
          </form>
        </div>
      )}
      <button className="chatbot-toggle" onClick={toggleChat} onMouseDown={handleMouseDown}>
        <img src="/images/robot.png" alt="Chat Bot" />
      </button>
    </div>
  );
});

export default ChatBot;
