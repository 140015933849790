function updateHeaderHeight() {
  const header = document.querySelector('header'); // 确保这里的选择器匹配你的 header 元素
  if (header) {
    const height = header.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${height}px`);
  }
  let vh = window.innerHeight;
  console.log('vh:', vh);
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function initHeaderHeightObserver() {
  updateHeaderHeight(); // 初始设置

  // 监听窗口大小变化
  window.addEventListener('resize', updateHeaderHeight);

  // 使用 ResizeObserver 监听 header 大小变化
  const header = document.querySelector('header');
  if (header && window.ResizeObserver) {
    const resizeObserver = new ResizeObserver(updateHeaderHeight);
    resizeObserver.observe(header);
  }
}

export { initHeaderHeightObserver };
