const config = {
  apiEndpoint: 'https://api.360.cn/v1/chat/completions', // 确保这是正确的 API 端点
  apiKey: process.env.REACT_APP_API_KEY, // 确保在 .env 文件中设置了这个环境变量
  initialPrompts: {
    'CEO老毛': '你好，我是CEO老毛，欢迎来到我的公司，我和我的AI小助理们很欢迎您，有什么可以帮助你的么?',
    'AI程序员小吕': '你好，我是AI程序员小吕，今天的开发任务可真重，网站又出啥事儿了，有事情快点说!',
    'AI设计师小沙': '你好，我是AI设计师小沙，网站的视频和图标就是我生成的，有什么不好的体验么?我们马上更改',
    'AI编导小曾': '你好，我是AI编导小曾，看过我们的小红书和抖音了么？没错，都是我做的，厉害吧',
    'AI演员小贾': '你好，我是AI演员小贾，看我的处境首秀么？对的，我就是那个用AI生成的虚拟人',
    'AI营销师小苗': '你好，我是AI营销师小苗，你看到的一些都是我的主意',
    'AI秘书小杨': '你好，我是AI秘书小杨，上到数据汇报，下到八卦消息，公司就没有我不知道的事情',
    'AI客服小容': '你好，我是第八号员工AI客服小容，很高兴为您服务，有什么可以帮到你的么？'
  }
};

export default config;
