import React, { useEffect, useRef } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Mission from './components/Mission';
import About from './components/About';
import Footer from './components/Footer';
import ChatBot from './components/ChatBot';
import './App.css';

function App() {
  const chatbotRef = useRef();

  useEffect(() => {
    // 设置平滑滚动行为
    document.documentElement.style.scrollBehavior = 'smooth';
    return () => {
      document.documentElement.style.scrollBehavior = '';
    };
  }, []);

  const initializeChat = (assistant) => {
    if (chatbotRef.current) {
      chatbotRef.current.initializeChat(assistant);
    }
  };

  return (
    <div className="App">
      <Header initializeChat={initializeChat} />
      <Hero />
      <Mission />
      <About initializeChat={initializeChat} />
      <Footer />
      <ChatBot ref={chatbotRef} />
    </div>
  );
}

export default App;
