import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';  // 确保这行存在
import './global.css';  // 添加这行
import App from './App';
import { initHeaderHeightObserver } from './utils/headerHeight';

// 在其他导入之后
import './global.css';

// 在 ReactDOM.render 之前添加这段代码
document.documentElement.style.setProperty('scrollbar-width', 'none');
document.documentElement.style.setProperty('-ms-overflow-style', 'none');
document.documentElement.style.overflow = 'auto';
document.body.style.overflow = 'overlay';

// 隐藏 WebKit 滚动条
const style = document.createElement('style');
style.textContent = `
  ::-webkit-scrollbar {
    display: none;
  }
`;
document.head.append(style);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// 初始化 header 高度观察器
initHeaderHeightObserver();
