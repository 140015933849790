import React from 'react';
import './Mission.css';

const Mission = () => {
  return (
    <section  id="mission"
      className="mission"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/misson.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}
    >
      <div className="mission-content">
        <h2>我们的使命</h2>
        <p className="mission-slogan">
          让每个人都可以专注自己的创意，<br />
          实现，让AI助理去实现吧。
        </p>
        <div className="mission-items">
          <div className="mission-item">
            <h3>拟人化感知</h3>
            <p>理解客户的情感和需求，通过自然语言处理和客户画像分析，洞悉客户意图，提供个性化的交互体验，让客户感受到被理解和重视。</p>
          </div>
          <div className="mission-item">
            <h3>智能化分析</h3>
            <p>随时待命，了解客户的日常行为模式，预测客户需求，提供准确、主动的供给建议，让客户感受到贴心的服务。</p>
          </div>
          <div className="mission-item">
            <h3>个性化互动</h3>
            <p>自动化的感知数据，生产关怀的任务清单，通过语音、文字和表情等多模态交互方式，让客户感受到温暖和关怀。</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
