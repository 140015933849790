import React from 'react';
import './About.css';

const About = ({ initializeChat }) => {
  return (
    <section id="about" className="about" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/about.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed'
    }}>
      <div className="about-content">
        <h2>关于我们</h2>
        <h3>这是一个CEO和他的AI小助理们</h3>
        <div className="team-structure">
          <div className="ceo">
            <span className="role">CEO 老毛</span>
            <button onClick={() => initializeChat('CEO老毛')}>去找他聊聊</button>
          </div>
          <div className="assistants">
            <div className="assistant">
              <span className="role">AI程序员小吕</span>
              <button onClick={() => initializeChat('AI程序员小吕')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI设计师小沙</span>
              <button onClick={() => initializeChat('AI设计师小沙')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI编导小曾</span>
              <button onClick={() => initializeChat('AI编导小曾')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI演员小贾</span>
              <button onClick={() => initializeChat('AI演员小贾')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI营销师小苗</span>
              <button onClick={() => initializeChat('AI营销师小苗')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI秘书小杨</span>
              <button onClick={() => initializeChat('AI秘书小杨')}>去找他聊聊</button>
            </div>
            <div className="assistant">
              <span className="role">AI客服小容</span>
              <button onClick={() => initializeChat('AI客服小容')}>去找他聊聊</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
