import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Header.css';

const Header = ({ initializeChat }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const headerRef = useRef(null);

  const getScrollTop = () => {
    return  document.documentElement.scrollTop || document.body.scrollTop || 0;
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = getScrollTop();
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    
    if (currentScrollY < headerHeight) {
      setIsAtTop(true);
      setIsVisible(true);
    } else {
      setIsAtTop(false);
      if (currentScrollY < lastScrollY) {
        // Scrolling up
        setIsVisible(true);
      } else {
        // Scrolling down
        setIsVisible(false);
      }
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header 
      ref={headerRef}
      className={`header ${isVisible ? 'visible' : 'hidden'} ${isAtTop ? 'at-top' : 'not-at-top'}`}
    >
      <div className="logo-container">
        <div className="logo">
          <img src="/logo.png" alt="人人都是CEO" className="icon" />
        </div>
        <div className="logo-text">
          <h1 className="title">人人都是CEO</h1>
          <p className="subtitle">AI让每个人都是管理者</p>
        </div>
      </div>
      <nav>
        <ul>
          <li><a href="#hero">首页</a></li>
          <li><a href="#mission">使命</a></li>
          <li><a href="#about">关于我们</a></li>
        </ul>
      </nav>
      <button className="cooperation-btn" onClick={() => initializeChat('CEO老毛')}>合作咨询</button>
    </header>
  );
};

export default Header;
