import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="logo-container">
            <div className="logo">
              <img src="/logo.png" alt="人人都是CEO" className="icon" />
            </div>
            <div className="logo-text">
              <h1 className="title">人人都是CEO</h1>
              <p className="subtitle">AI让每个人都是管理者</p>
            </div>
          </div>
        </div>
        <div className="footer-center">
          <div className="qr-codes">
            <div className="qr-code">
              <img src="/images/qrcode.jpg" alt="微信公众号" />
              <p>微信公众号</p>
            </div>
            <div className="qr-code">
              <img src="/images/qrcode.jpg" alt="视频号" />
              <p>视频号</p>
            </div>
            <div className="qr-code">
              <img src="/images/qrcode.jpg" alt="抖音号" />
              <p>抖音号</p>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-info">
            <p>商务合作: apostle9891@foxmail.com</p>
            <p>网站地址: 北京市朝阳区360大厦</p>
            <p>Copyright © 2024 apostle的个人记录</p>
            <p>京ICP备2022002381号</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
